export enum SUBSCRIPTION_STATUS {
    ACTIVE = 1,
    FAILED = 0,
    PENDING = 2,
    EXPIRED = 3,
    CANCELLED = 4,
    ON_HOLD = 5,
    READY = 6,
    PENDING_ACTIVATION = 7,
}

export const STATUS_LABELS: Record<number, string> = {
    0: 'FAILED',
    1: 'ACTIVE',
    2: 'PENDING',
    3: 'EXPIRED',
    4: 'CANCELLED',
    5: 'ON_HOLD',
    7: 'PENDING ACTIVATION',
}

export const MERCHANT_STATUS_LABELS: Record<number, string> = {
    5: 'REGULAR',
    6: 'ALAYA',
}

export const STATUS_CLASSES: Record<number, string> = {
    0: 'text-danger',
    1: 'text-success',
    2: 'text-warning',
    3: 'text-danger',
    4: 'text-danger',
    5: 'text-secondary',
    6: 'text-success',
    7: 'text-secondary',
}

export type TableColumn = {
    key: string;
    label: string;
    sorter: boolean;
    sorterKey?: string;
    _style?: React.CSSProperties;
};

interface Agency {
    agencyId: number;
    agencyName: string;
    agencyEmail: string;
    contactMobile: string;
}

interface User {
    username: string;
    email: string;
    mobile: string;
    agency: Agency;
}

export type DataItem = {
    id: number;
    name: string;
    userId: number;
    startDate: null | string;
    endDate: null | string;
    fee: number;
    adsBudget: number;
    promoteBudget: number;
    refreshBudget: number;
    subscriptionTypeId: number;
    status: number;
    user: User;
}

export enum SubscriptionType {
    ALAYA_ONE_WEEK = "1 week Subscription",
    ALAYA_ONE_MONTH = "1 month Subscription",
    ALAYA_THREE_MONTH = "3 months Subscription",
    REGULAR = "Regular Package"
}

export enum OFFERTYPE {
    DISCOUNT = 1,
    BUY1_GET1 = 2,
    VOUCHER = 3,
    BUNDLE = 4,
}

export const offerTypes:IQLSelectOption[] = [
    {
        id: 1,
        name: "Discount",
    },
    {
        id: 2,
        name: "Buy 1 Get 1",
    },
    {
        id: 3,
        name: "Voucher",
    },
    {
        id: 4,
        name: "Bundle",
    },
]

export const redemptionLimits:IQLSelectOption[] = [
    {
        id: 1,
        name: "Un Limited",
    },
    {
        id: 2,
        name: "Limited",
    },
]