export const enum ENV {
  DEV = 'dev',
  QA = 'qa',
  PROD = 'prod',
}
const APP_NAME = process.env.NEXT_PUBLIC_APP_NAME ?? ''
export const APP_PREFIX = APP_NAME.substring(0, 1)
export const SEARCH_DEBOUNCE_TIME = 700
export const OPEN_URL_DELAY = 1000
const QL_ENV = (process.env.REACT_APP_ENV ?? 'dev') as ENV

const config: any = {
  vehicle_admin: {
    ql_env: QL_ENV,
    media_url:
      process.env.REACT_APP_MEDIA_URL ??
      'https://qlv-media-dev.qatarliving.com',
    base_url:
      process.env.REACT_APP_BASE_URL ?? 'https://qlvn-be-as.azurewebsites.net',
    generate_signed_url:
      process.env.REACT_APP_GENERATE_SIGNED_URL ??
      'https://bo-dev.qatarliving.com/vehicles/generateSignedURL',
    ql_url: process.env.REACT_APP_QL_URL ?? 'https://qlv-dev.qatarliving.com',
  },
  property_admin: {
    ql_env: QL_ENV,
    base_url:
      process.env.REACT_APP_BASE_QLP_URL ??
      'https://qlpbackenddev.azurewebsites.net',
    images_cdn_url:
      process.env.REACT_APP_CDN_BASE_QLP_URL ??
      'https://qlpnext-dev-eqhcdvg7dbhbcrfr.a03.azurefd.net/',
    qlp_url: process.env.REACT_APP_QLP_URL ?? 'https://qlp-dev.qatarliving.com',
    signed_url: process.env.REACT_APP_BASE_QLP_URL
      ? process.env.REACT_APP_BASE_QLP_URL + '/properties'
      : 'https://qlpbackenddev.azurewebsites.net/properties',
  },
  reward_admin: {
    ql_env: QL_ENV,
    base_url:
      process.env.REACT_APP_BASE_QLR_URL ??
      'https://qlr-bo-dev.qatarliving.com',
    images_cdn_url:
      process.env.REACT_APP_CDN_BASE_QLR_URL ??
      'https://qlrnext-dev-dhcuccfxf3a8fga7.a03.azurefd.net/',
    qlr_url: process.env.REACT_APP_QLR_URL ?? 'https://qlr-dev.qatarliving.com',
    signed_url: process.env.REACT_APP_BASE_QLR_URL
      ? process.env.REACT_APP_BASE_QLR_URL + '/images'
      : 'https://qlr-bo-dev.qatarliving.com/images',
  },
  banner_admin: {
    ql_env: QL_ENV,
    base_url:
      process.env.REACT_APP_BASE_QLR_URL ??
      'https://qlr-bo-dev.qatarliving.com',
    base_url_common:
      process.env.NEXT_PUBLIC_API_BASE_URL_COMMON ??
      `https://ql-shared-${QL_ENV}.qatarliving.com`,
    images_cdn_url:
      process.env.REACT_APP_CDN_BASE_SHARED &&
      'https://qlsharedstorage.blob.core.windows.net/',
  },
}

export const getConfig = () => {
  const selectedVerticle = localStorage.getItem('selectedVerticle') as string
  return config[JSON.parse(selectedVerticle)]
}
