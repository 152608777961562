import { createContext, useContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLocalStorage } from './useLocalStorage'
import { UserData, UserRole } from '../constants/Usertype'
const AuthContext = createContext<any>(null)
export const AuthProvider = ({ children }: { children: any }) => {
  const [user, setUser] = useLocalStorage('adminuser', null)
  const [selectedVerticle, setSelectedVerticle] = useLocalStorage(
    'selectedVerticle',
    null,
  )
  const navigate = useNavigate()
  const login = async (data: UserData) => {
    // TODO: get available roles from hook
    const roles: UserRole[] = []
    if (data && Object.values(data.user.roles).length) {
      if (Object.values(data.user.roles).indexOf('administrator') > 0) {
        roles.push({ name: 'Vehicle', key: 'vehicle_admin' })
        roles.push({ name: 'Property', key: 'property_admin' })
        roles.push({ name: 'Rewards', key: 'reward_admin' })
      } else {
        if (Object.values(data.user.roles).indexOf('vehicle admin') > 0) {
          roles.push({ name: 'Vehicle', key: 'vehicle_admin' })
        }
        if (Object.values(data.user.roles).indexOf('property admin') > 0) {
          roles.push({ name: 'Property', key: 'property_admin' })
        }
        if (Object.values(data.user.roles).indexOf('reward admin') > 0) {
          roles.push({ name: 'Rewards', key: 'reward_admin' })
        }
      }
    }
    if (roles.length > 0) {
      const defaultRole = roles[0]
      setSelectedVerticle(defaultRole.key)
    }
    setUser(data)
    setTimeout(() => {
      if (roles[0].key === 'vehicle_admin' || (roles && roles.length > 1)) navigate('/adsmanagement')
      else if(roles[0].key === 'property_admin') navigate('/subscriptionmanagement')
      else if(roles[0].key === 'reward_admin') navigate('/merchantManagement')
    }, 500)
  }
  const logout = () => {
    setUser(null)
    navigate('/login', { replace: true })
  }

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
    }),
    [user],
  )
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return useContext(AuthContext)
}
