import { IFieldTypes, LABEL_SUFFIX } from "../../../../constants/form-and-fields"

export enum FIELD_DATA_KEY {
    REDEMPTION_TYPE = "redemptionType",
    REDEMPTION_LIMIT = "redemptionLimit",
    IS_MULTI_ITEMS = "isMultiItems",
    PRICE = "price",
    VOUCHER = "voucherPrice",
    DISCOUNT_PERCENTAGE = "discountPercentage",
    RANGE_DATE_PICKER = 'rangeDatePicker',
    TITLE = "title",
    DESCRIPTION = "description",
    LOCATIONS = "locations",
}

export enum FIELD_NAMES {
    REDEMPTION_TYPE = "redemptionType",
    REDEMPTION_LIMIT = "redemptionLimit",
    IS_MULTI_ITEMS = "isMultiItems",
    PRICE = "price",
    VOUCHER = "voucherPrice",
    DISCOUNT_PERCENTAGE = "discountPercentage",
    RANGE_DATE_PICKER = 'rangeDatePicker',
    TITLE = "title",
    DESCRIPTION = "description",
    LOCATIONS = "locations",
    START_DATE = "startDate",
    END_DATE = "endDate",
}

export const redemptionTypeField: IFormField = {
    type: IFieldTypes.SELECT,
    dataKey: FIELD_DATA_KEY.REDEMPTION_TYPE,
    props: {
      name: FIELD_NAMES.REDEMPTION_TYPE,
      rules: { required: 'Redemption Type is required.' },
      inputProps: {
        label: 'Redemption',
        labelSuffix: LABEL_SUFFIX.REQUIRED_FIELD,
        fullWidth: false,
        width:'65%',
        marginRight:'14px'
      },
      selectProps: {
        emptyValueLabel: 'Choose',
      },
      optionsProps: {
        optionArray: [],
      },
    },
}

export const redemptionLimitField: IFormField = {
    type: IFieldTypes.INPUT,
    dataKey: FIELD_DATA_KEY.REDEMPTION_LIMIT,
    props: {
      name: FIELD_NAMES.REDEMPTION_LIMIT,
      inputProps: {
        fullWidth:false,
        width:'31%',
        label: 'Limit Per User',
        labelSuffix: LABEL_SUFFIX.REQUIRED_FIELD,
      },
      inputTextFieldProps: {
        type: 'Number',
        shouldParse: true,
        charMaxLength: 10,
      },
    },
}

export const isMultiItemsField: IFormField = {
    type: IFieldTypes.CHECKBOX,
    dataKey: FIELD_DATA_KEY.IS_MULTI_ITEMS,
    props: {
        name: FIELD_NAMES.IS_MULTI_ITEMS,
        inputProps: {
        label: 'This Offer is for Multiple Items',
        fullWidth: true,
        },
    },
}

export const PriceField: IFormField = {
    type: IFieldTypes.INPUT,
    dataKey: FIELD_DATA_KEY.PRICE,
    props: {
      name: FIELD_NAMES.PRICE,
      rules: {
        required: 'Price is required.',
        pattern: {
          value: /^\d*\.?\d+$/,
          message: 'Price must be a valid number.',
        },
        validate: {
          positive: (value: string) =>
            parseFloat(value) > 0 || 'Price must be greater than 0.',
        },
      },
  
      inputProps: {
        fullWidth: true,
        label: 'Original Price',
        labelSuffix: LABEL_SUFFIX.REQUIRED_FIELD,
      },
      inputTextFieldProps: {
        type: 'text',
        shouldParse: true,
        adornment: 'qar',
        charMaxLength: 10,
      },
    },
}

export const VoucherField: IFormField = {
  type: IFieldTypes.INPUT,
  dataKey: FIELD_DATA_KEY.VOUCHER,
  props: {
    name: FIELD_NAMES.VOUCHER,
    rules: {
      required: 'Voucher value is required.',
      pattern: {
        value: /^\d*\.?\d+$/,
        message: 'Voucher value must be a valid number.',
      },
      validate: {
        positive: (value: string) =>
          parseFloat(value) > 0 || 'Voucher value must be greater than 0.',
      },
    },

    inputProps: {
      fullWidth: true,
      label: 'Voucher value',
      labelSuffix: LABEL_SUFFIX.REQUIRED_FIELD,
    },
    inputTextFieldProps: {
      type: 'text',
      shouldParse: true,
      adornment: 'qar',
      charMaxLength: 10,
    },
  },
}

export const DiscountPercentField: IFormField = {
    type: IFieldTypes.INPUT,
    dataKey: FIELD_DATA_KEY.DISCOUNT_PERCENTAGE,
    props: {
      name: FIELD_NAMES.DISCOUNT_PERCENTAGE,
      rules: {
        required: 'Discount Percentage is required.',
        pattern: {
          value: /^\d*\.?\d+$/,
          message: 'Discount Percentage must be a valid number.',
        },
        validate: {
          positive: (value: string) =>
            parseFloat(value) > 0 || 'Discount Percentage must be greater than 0.',
        },
      },

      inputProps: {
        fullWidth: true,
        label: 'Discount Percentage',
        labelSuffix: LABEL_SUFFIX.REQUIRED_FIELD,
      },
      inputTextFieldProps: {
        type: 'text',
        shouldParse: true,
        adornment: '%',
        charMaxLength: 3,
      },
    },
}

export const TitleField: IFormField = {
  type: IFieldTypes.INPUT,
  dataKey: FIELD_DATA_KEY.TITLE,
  props: {
    name: FIELD_NAMES.TITLE,
    rules: {
      required: 'Title is required',
    },
    inputProps: {
      fullWidth: true,
      label: 'Title',
      labelSuffix: LABEL_SUFFIX.REQUIRED_FIELD,
    },
    inputTextFieldProps: {
      charMaxLength: 80,
      showLimitValue: true,
    },
  },
}

export const DescriptionField: IFormField = {
  type: IFieldTypes.EDITOR,
  dataKey: FIELD_DATA_KEY.DESCRIPTION,
  props: {
    name: FIELD_NAMES.DESCRIPTION,
    rules: { required: 'Description is required' },
    inputProps: {
      fullWidth: true,
      label: 'Description',
      labelSuffix: LABEL_SUFFIX.REQUIRED_FIELD,
    },
  },
}

export const LocationsField: IFormField = {
  type: IFieldTypes.SELECT,
  dataKey: FIELD_DATA_KEY.LOCATIONS,
  props: {
    name: FIELD_NAMES.LOCATIONS,
    rules: { required: 'Locations is required.' },
    inputProps: {
      label: 'Location',
      labelSuffix: LABEL_SUFFIX.REQUIRED_FIELD,
      fullWidth: true,
    },
    selectProps: {
      emptyValueLabel: 'Choose',
      multiple:true
    },
    optionsProps: {
      optionArray: [],
    },
  },
}

export const RangeDatePicker: IFormField = {
  type: IFieldTypes.RANGE_DATE_PICKER,
  dataKey: FIELD_DATA_KEY.RANGE_DATE_PICKER,
  props: {
    name: FIELD_NAMES.RANGE_DATE_PICKER,
  }
}