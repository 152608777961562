export const enum AD_MODE {
    // eslint-disable-next-line no-unused-vars
    CREATE = 'create',
    // eslint-disable-next-line no-unused-vars
    EDIT = 'edit',
}

export const enum LABEL_SUFFIX {
    // eslint-disable-next-line no-unused-vars
    REQUIRED_FIELD = '*',
}

export enum IFieldTypes {
    SELECT = 'select',
    INPUT = 'input',
    RADIO = 'radio',
    CHECKBOX = 'checkbox',
    PHONE_NUMBER = 'phoneNumber',
    EDITOR = 'editor',
    FEATURES = 'features',
    MAP = 'map',
    BLUE_PLATE = 'bluePlate',
    LANGUAGES = 'languages',
    FILE = 'file',
    RANGE_DATE_PICKER = 'rangeDatePicker'
  }