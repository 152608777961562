import { getConfig } from '../config'
import { STYLE_NAME, STYLE_SIZES } from '../views/properties/constants'
import { KAHRAMA_INTERNET } from '../views/properties/constants'
import { FileWithPreview } from '../views/properties/constants/FileWithPreview'
import {
  AD_MODE,
  FIELD_DATA_KEY,
  FIELD_NAMES,
} from '../views/properties/constants/form-and-fields'
import {
  ImageOrder,
  SelectedFormValues,
} from '../views/properties/create-edit/components/ad-form/create-ad-form.types'
import { LabelValue } from '../views/vehicles/adsmanagement/type'
import { OfferSubCategory } from '../views/rewards/types'
import { FIELD_NAMES as REWARDS_FIELD_NAMES } from '../views/rewards/constants/form-and-fields'
import { RewardFormData, TransformedRewardData } from '../views/rewards/types'
import { FORM_CONFIGURATION as RewardSchema } from '../views/rewards/constants/form-configuration'
interface GetFileIdentifierProps {
  id: number | string
  name: string
}

interface LabelValueQLP {
  name: string
  id: string | number
  purposeId?: string | number
  categoryId?: string | number
  featureGroup?: string
}

export function generateYearObjects(numberOfYears: number) {
  const currentYear = new Date().getFullYear()
  const years = []

  for (let i = 0; i <= numberOfYears; i++) {
    const year = currentYear + i
    years.push({ label: year.toString(), value: year.toString() })
  }

  return years
}

export const convertCarFeaturesIntoCategorized = (
  filterOptions: LabelValue[],
) => {
  const groupedOptions: { [key: string]: LabelValue[] } = {}

  filterOptions?.forEach((option) => {
    if ('group' in option && 'label' in option && 'value' in option) {
      const group = option.group
      if (!group) {
        return
      }
      if (!groupedOptions[group]) {
        groupedOptions[group] = []
      }
      groupedOptions[group].push({
        label: option.label,
        value: option.value,
      })
    }
  })

  return groupedOptions
}

export const convertFeaturesIntoCategorized = (
  filterOptions: LabelValueQLP[],
) => {
  const groupedOptions: { [key: string]: LabelValueQLP[] } = {}

  filterOptions?.forEach((option) => {
    if ('featureGroup' in option && 'name' in option && 'id' in option) {
      const group = option?.featureGroup
      if (!group) {
        return
      }
      if (!groupedOptions[group]) {
        groupedOptions[group] = []
      }
      groupedOptions[group].push({
        name: option.name,
        id: option.id,
      })
    }
  })
  return groupedOptions
}

export const convertToISODate = (dateStr: string) => {
  const date = new Date(dateStr)
  if (isNaN(date.getTime())) {
    throw new Error(`Invalid date format: ${dateStr}`)
  }
  return date.toISOString()
}

export const getFilteredCategories = (
  purposeId: string,
  data: FilterData,
): Category[] => {
  //TODO: This can be achieved in a more efficient way by comparing it with == instead of ===
  // Convert purposeId to a number for comparison
  const numericPurposeId = Number(purposeId)

  // Filter categories by the given purposeId
  return data.category.filter(
    (category) => category.purposeId === numericPurposeId,
  )
}

export const getFilteredSubCategories = (
  categoryId: string,
  data: RewardsFilterData,
): OfferSubCategory[] => {
  //TODO: This can be achieved in a more efficient way by comparing it with == instead of ===
  // Convert categoryId to a number for comparison
  const numericcategoryId = Number(categoryId)

  // Filter categories by the given categoryId
  return data.subCategory.filter(
    (subCategory) => subCategory.categoryId === numericcategoryId,
  )
}

export const calculateUsed = (budget: Budget): Budget => {
  // Helper function to calculate the total budget
  const calculateTotalBudgetAndBalance = (
    category: any[],
  ): { totalBudget: number; totalBalance: number } => {
    const originalBudget = category[0].original_budget
    const originalBalance = category[0].original_balance
    const addons = category[1]?.[0] || [] // Extract addons, handle if empty or undefined
    const addonsBudget = addons.reduce(
      (sum: number, addon: any) => sum + addon.addon_budget,
      0,
    )
    const addonsBalance = addons.reduce(
      (sum: number, addon: any) => sum + addon.addon_balance,
      0,
    )

    return {
      totalBudget: originalBudget + addonsBudget,
      totalBalance: originalBalance + addonsBalance,
    }
  }

  return {
    ads: budget.ads.map((ad) => ({
      ...ad,
      used: ad.original_budget - ad.original_balance,
    })),
    promote: budget.promote.map((promotion, index) => {
      if (index === 0) {
        const { totalBudget, totalBalance } = calculateTotalBudgetAndBalance(
          budget.promote,
        )
        return {
          ...promotion,
          original_budget: totalBudget,
          original_balance: totalBalance,
          used: totalBudget - totalBalance,
        }
      }
      return promotion
    }),
    refresh: budget.refresh.map((refresh, index) => {
      if (index === 0) {
        const { totalBudget, totalBalance } = calculateTotalBudgetAndBalance(
          budget.refresh,
        )
        return {
          ...refresh,
          original_budget: totalBudget,
          original_balance: totalBalance,
          used: totalBudget - totalBalance,
        }
      }
      return refresh
    }),
  }
}

export const getFilteredPropertyTypes = (
  purposeId: string,
  categoryId: string,
  data: FilterData,
): PropertyType[] => {
  // Convert purposeId and categoryId to numbers for comparison
  const numericPurposeId = Number(purposeId)
  const numericCategoryId = Number(categoryId)

  // Check if the category exists with the given categoryId and belongs to the specified purposeId
  const selectedCategory = data.category.find(
    (category) =>
      category.id === numericCategoryId &&
      category.purposeId === numericPurposeId,
  )

  // If a category is found, filter property types by the selected categoryId
  return selectedCategory
    ? data.propertyType.filter(
        (propertyType) => propertyType.categoryId === numericCategoryId,
      )
    : []
}

export const getFileIdentifier = ({ id, name }: GetFileIdentifierProps) => {
  return `${id}-${name}`
}
export const uuid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

interface ParseLocationDataProps {
  data: LocationQLP[] | InternationalLocation[]
  haveHyphen?: boolean
  includeZone?: boolean
}
export const parseLocationData = ({
  data,
  haveHyphen = false,
  includeZone = true,
}: ParseLocationDataProps) => {
  return (
    (data as LocationQLP[])
      ?.flatMap((item) => {
        if (includeZone) {
          return (
            item?.zones?.map((zone) => ({
              id: `z=${zone.id}&a=${item.id}&c=${item?.city?.id}`,
              name: haveHyphen
                ? `${zone.id} - ${item.name} - ${item?.city?.name}`
                : `${zone.id} ${item.name} (${item?.city?.name})`,
              latitude: item.latitude,
              longitude: item.longitude,
            })) ?? []
          )
        } else {
          return {
            id: `a=${item.id}&c=${item?.city?.id}`,
            name: haveHyphen
              ? `${item.name}`
              : `${item.name} (${item?.city?.name})`,
            latitude: item.latitude,
            longitude: item.longitude,
          }
        }
      })
      .filter(Boolean) ?? []
  )
}

const convertAbleData = [
  'acTypeId',
  'bathroomId',
  'bedroomId',
  'categoryId',
  'commissionId',
  'depositId',
  'freeMonthId',
  'furnishingId',
  'kitchenId',
  'latitude',
  'longitude',
  'parkingId',
  'purposeId',
  'realEstateTypeId',
  'referenceNumber',
  'rentalPeriodId',
  'squareMeters',
  'propertyTypeId',
  'zoneId',
  'cityId',
  'areaId',
  'building',
  'street',
  'price',
  'priceFrequencyId',
]
const booleanConvertableFields = ['hasKahramaa', 'hasInternet']
export const convertFormData = (
  formData: SelectedFormValues | Partial<SelectedFormValues>,
) => {
  // Convert string fields to numbers
  convertAbleData.forEach((key) => {
    const value = formData[key as keyof SelectedFormValues]

    // Check if the value is not undefined or an empty string
    if (
      value !== undefined &&
      value !== '' &&
      typeof value === 'string' &&
      key !== 'referenceNumber'
    ) {
      ;(formData[key as keyof SelectedFormValues] as unknown as number) =
        Number(value)
    }
  })

  booleanConvertableFields.forEach((key) => {
    const value = formData[key as keyof SelectedFormValues]

    // Check if the value is not undefined or an empty string
    if (value !== undefined && value !== '') {
      ;(formData[key as keyof SelectedFormValues] as unknown as boolean) =
        Boolean(Number(value) === KAHRAMA_INTERNET.INCLUDED)
    }
  })

  return formData
}

export const purifyFormData = (
  data: SelectedFormValues | Partial<SelectedFormValues>,
  mode: AD_MODE,
) => {
  const purifiedData: Partial<SelectedFormValues> = {}

  for (const key in data) {
    const value = data[key as keyof SelectedFormValues]

    if (mode === AD_MODE.CREATE) {
      // Filter out empty strings, null, undefined, and empty arrays in CREATE mode
      if (
        value !== '' && // Exclude empty strings
        value !== null && // Exclude null
        value !== undefined && // Exclude undefined
        !(Array.isArray(value) && value.length === 0) // Exclude empty arrays
      ) {
        const trimmedValue = typeof value === 'string' ? value.trim() : value
        ;(purifiedData[key as keyof SelectedFormValues] as unknown as
          | string
          | number
          | boolean
          | (ImageOrderWithUri | ImageOrderWithoutUri)[]
          | ImageOrder[]
          | FileWithPreview[]
          | string[]
          | number[]) = trimmedValue
      }
    } else if (mode === AD_MODE.EDIT) {
      // In EDIT mode, replace empty strings with null, keep other values the same
      const trimmedValue = typeof value === 'string' ? value.trim() : value

      if (trimmedValue === '') {
        // TODO: Type issue to check for selectedFormValues to handle null
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        purifiedData[key as keyof SelectedFormValues] = null
      } else if (
        value !== null && // Exclude null
        value !== undefined && // Exclude undefined
        !(Array.isArray(value) && value.length === 0) // Exclude empty arrays
      ) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        purifiedData[key as keyof SelectedFormValues] = trimmedValue
      }
    }
  }
  if (Array.isArray(purifiedData.imagesToUpload)) {
    delete purifiedData.imagesToUpload
  }
  if (purifiedData?.descriptionLength) {
    delete purifiedData.descriptionLength
  }
  if (purifiedData?.zone) {
    delete purifiedData.zone
  }
  if (purifiedData?.imagesUploadInProgress) {
    delete purifiedData.imagesUploadInProgress
  }
  return purifiedData
}

const imageLoader = ({ src }: { src: string }) => {
  if (src === null || src === 'null') {
    return src
  }
  if (!src?.startsWith?.('http')) {
    return getConfig().images_cdn_url + src
  }
  return src
}

export const constructImageUri = ({
  image,
  styleName,
  cacheValue,
  containerName = 'ad-images-output',
  loader = true,
}: {
  image: string | undefined
  styleName?: STYLE_NAME
  cacheValue?: string
  containerName?: 'ad-images-output' | 'ad-place-images' | 'banners'
  loader?: boolean
}) => {
  if (!image) {
    return {
      imageUri: image,
      loader: imageLoader,
    }
  }

  let imageUri = `${containerName}/${image}`

  if (styleName && styleName != STYLE_NAME.ORIGINAL) {
    const urlParts = image.split('/')
    const imageBasePath = urlParts.slice(0, 2).join('/')
    const fileName = urlParts.slice(2).join('/')
    const fileNameWithoutExt = fileName.split('.').slice(0, -1).join('.')
    const fileSize = STYLE_SIZES[styleName]
    imageUri = `${containerName}/${imageBasePath}/thumbnail/${fileNameWithoutExt}_${fileSize.join(
      'x',
    )}.webp`
  }

  if (cacheValue) {
    imageUri += `?cache_t=${cacheValue}`
  }
  if (loader === false) {
    imageUri = getConfig().images_cdn_url + imageUri
  }
  return {
    imageUri,
    loader: imageLoader,
  }
}

export function filterImagesWithoutId(images: ImageOrder[]): ImageOrder[] {
  return images.filter((image) => image.id === undefined)
}

export const getImagesOrderQLP = (
  files: ImageTypes[],
): (ImageOrderWithUri | ImageOrderWithoutUri)[] => {
  return files.map((file, index) => {
    const { uri, viewpoint, id } = file
    const sortOrder = index + 1

    return id === undefined
      ? ({ id: undefined, viewpoint, sortOrder, uri } as ImageOrderWithUri)
      : ({ id, viewpoint, sortOrder, uri } as ImageOrderWithoutUri)
  })
}

export const convertToDate = (dateString: string) => {
  // Split the date and time parts
  const [datePart, timePart] = dateString.split(', ')

  // Split the date part into day, month, and year
  const [day, month, year] = datePart.split('/').map(Number)
  // Split the time part into hours, minutes, seconds and period (am/pm)
  const [time, period] = timePart.split(' ')
  // eslint-disable-next-line prefer-const
  let [hours, minutes, seconds] = time.split(':').map(Number)

  // Convert to 24-hour format if needed
  if (period.toLowerCase() === 'pm' && hours !== 12) {
    hours += 12
  } else if (period.toLowerCase() === 'am' && hours === 12) {
    hours = 0
  }

  // Create a new JavaScript Date object
  const date = new Date(year, month - 1, day, hours, minutes, seconds)

  return date
}

export const formatCurrency = (value = 0) => {
  return Intl.NumberFormat().format(value)
}

export const formatNumberToPercentage = (number: number) => {
  return Math.round((number + Number.EPSILON) * 100) / 100
}

export const impressionToViews = (
  views: number,
  impressions: number,
): number => {
  if (impressions === 0) return 0
  const result = (views / impressions) * 100
  return isFinite(result) ? formatNumberToPercentage(result) : 0
}

export const viewsToLeads = (leads: number, views: number): number => {
  if (views === 0) return 0
  const result = (leads / views) * 100
  return isFinite(result) ? formatNumberToPercentage(result) : 0
}

interface HandleParseValue {
  selectedValue: string
  name?: string
  setValue?: (name: string, value: string) => void
}
export const handleParseValue = ({
  selectedValue,
  name = FIELD_DATA_KEY.ZONE,
  setValue,
}: HandleParseValue) => {
  if (name === FIELD_DATA_KEY.ZONE) {
    const idParts = selectedValue.split('&') // Assuming the format is "z=id&a=id&c=id"

    const zoneId =
      idParts.find((part) => part.startsWith('z='))?.split('=')[1] ?? ''
    const areaId =
      idParts.find((part) => part.startsWith('a='))?.split('=')[1] ?? ''
    const cityId =
      idParts.find((part) => part.startsWith('c='))?.split('=')[1] ?? ''

    // Setting the extracted values
    if (setValue) {
      setValue('zoneId', zoneId)
      setValue('cityId', cityId)
      setValue('areaId', areaId)
      setValue('street', '')
      setValue('building', '')
    }
  } else if (name === FIELD_NAMES.COUNTRY) {
    if (setValue) {
      setValue('cityId', '')
    }
  } else if (name === REWARDS_FIELD_NAMES.LOCATIONS) {
    if (setValue) setValue('locations', selectedValue)
  }
}

export const getDirtyFieldsQLP = (
  formData: SelectedFormValues,
  dirtyFields: Record<string, boolean>,
): Partial<SelectedFormValues> => {
  const result: Partial<SelectedFormValues> = {}

  // Handle dirty fields
  Object.keys(dirtyFields).forEach((key) => {
    const typedKey = key as keyof SelectedFormValues
    if (dirtyFields[typedKey] && formData[typedKey] !== undefined) {
      ;(result as any)[key] = formData[typedKey]
    }
  })

  if (formData.latitude) {
    result.latitude = formData.latitude
  }
  if (formData.longitude) {
    result.longitude = formData.longitude
  }
  // Always include special fields if they have data
  if ((formData.removeImages || []).length > 0) {
    result.removeImages = formData.removeImages
  }
  if ((formData.removeFeatures || []).length > 0) {
    result.removeFeatures = formData.removeFeatures
  }
  if ((formData.images || []).length > 0) {
    result.images = formData.images
  }
  if ((formData.newImages || []).length > 0) {
    result.newImages = formData.newImages
  }

  return result
}

export const formatNumberWithCommas = (input: string) => {
  return input.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const extractCitiesAndCountries = (data: LocationQLP[]) => {
  const cityArray = data.map((item) => ({
    id: item.id,
    name: item.name,
    country: item.country?.id, // Bind the country to the city
  }))
  // Use a Set to avoid duplicate countries
  const countrySet = new Set(
    data.map((item) => JSON.stringify(item.country)), // Serialize country to avoid duplicates
  )
  const countryArray: { id: number; name: string }[] = Array.from(
    countrySet,
  ).map((item) => JSON.parse(item)) // Parse back to object
  return {
    cities: cityArray,
    countries: countryArray,
  }
}
const isValid = (value: any): boolean => {
  return (
    value !== undefined &&
    value !== null &&
    !(Array.isArray(value) && value.length === 0)
  )
}

const isIncluded = (field: string, offerTypeId: number): boolean => {
  const { details_and_pricing } = RewardSchema[offerTypeId as number]
  const fields = details_and_pricing.map(
    (field: IFormField) => field.props.name,
  )
  return fields.includes(field)
}

const getURI = (img: ImageOrderWithUri | ImageOrderWithoutUri): string => {
  if ('uri' in img) {
    return img.uri
  }
  return ''
}

export const purifyRewardData = (
  formData: RewardFormData,
): Partial<TransformedRewardData> => {
  const purifiedData: Partial<TransformedRewardData> = {}
  const offerTypeId = formData.offerTypeId
  if (formData.addImages && isValid(formData.addImages))
    purifiedData.addImages = formData.addImages?.map((img) => ({
      uri: img.uri,
      sortOrder: img.sortOrder,
      id: img.id,
    }))
  if (isValid(formData.removeImages))
    purifiedData.removeImages = formData.removeImages
  if (isValid(formData.imageOrders))
    purifiedData.imageOrders = formData.imageOrders?.map((img) => ({
      uri: getURI(img),
      sortOrder: img.sortOrder,
      id: img.id,
    }))
  if (isValid(formData.isDealOfTheDay))
    purifiedData.isDealOfTheDay = formData.isDealOfTheDay
  if (isValid(formData.categoryId))
    purifiedData.categoryId = formData.categoryId
  if (isValid(formData.subCategoryId))
    purifiedData.subCategoryId = formData.subCategoryId
  if (isValid(formData.offerTypeId))
    purifiedData.offerTypeId = formData.offerTypeId
  if (
    isValid(formData.price) &&
    isIncluded('price', offerTypeId) &&
    !formData.isMultiItems
  )
    purifiedData.price = Number(formData.price)
  if (isValid(formData.voucherPrice && isIncluded('voucherPrice', offerTypeId)))
    purifiedData.voucherPrice = Number(formData.voucherPrice)
  if (
    isValid(formData.discountPercentage) &&
    isIncluded('discountPercentage', offerTypeId)
  )
    purifiedData.discountPercentage = Number(formData.discountPercentage)
  if (isValid(formData.isMultiItems) && isIncluded('isMultiItems', offerTypeId))
    purifiedData.isMultiItems = formData.isMultiItems
  if (isValid(formData.redemptionType))
    purifiedData.redemptionType = formData.redemptionType
  if (isValid(formData.redemptionLimit) && formData.redemptionType == 2)
    purifiedData.redemptionLimit = Number(formData.redemptionLimit)
  if (formData.redemptionType == 1) purifiedData.redemptionLimit = null
  if (isValid(formData.title)) purifiedData.title = formData.title
  if (isValid(formData.description))
    purifiedData.description = formData.description
  if (isValid(formData.subscriptionTypeId))
    purifiedData.subscriptionTypeId = formData.subscriptionTypeId
  if (isValid(formData.startDate)) purifiedData.startDate = formData.startDate
  if (isValid(formData.endDate)) purifiedData.endDate = formData.endDate
  if (isValid(formData.locations)) purifiedData.locationIds = formData.locations
  if (isValid(formData.contactMobile))
    purifiedData.contactMobile = formData.contactMobile
  if (isValid(formData.contactWhatsapp))
    purifiedData.contactWhatsapp = formData.contactWhatsapp
  return purifiedData
}
