import {  
    redemptionTypeField, 
    redemptionLimitField, 
    isMultiItemsField, 
    PriceField, 
    VoucherField,
    DiscountPercentField, 
    TitleField,
    DescriptionField,
    LocationsField,
    RangeDatePicker
} from "../form-and-fields"

import { OFFERTYPE } from "../../MerchantSubscription/types";

type RewardSchema = {
    [offerTypeId:number]:{
        details_and_pricing: IFormField[];
        description: IFormField[];
        location: IFormField[];
    }
}

export const FORM_CONFIGURATION: RewardSchema = {
    [OFFERTYPE.DISCOUNT]:{
        details_and_pricing:[          
            redemptionTypeField,
            redemptionLimitField,
            isMultiItemsField,
            PriceField,
            DiscountPercentField,
            RangeDatePicker
        ],
        description:[
            TitleField,
            DescriptionField
        ],
        location:[
            LocationsField
        ]
    },
    [OFFERTYPE.VOUCHER]:{
        details_and_pricing:[       
            redemptionTypeField,
            redemptionLimitField,
            VoucherField,
            RangeDatePicker
        ],
        description:[
            TitleField,
            DescriptionField
        ],
        location:[
            LocationsField
        ]
    },
    [OFFERTYPE.BUNDLE]:{
        details_and_pricing:[       
            redemptionTypeField,
            redemptionLimitField,
            PriceField,
            RangeDatePicker
        ],
        description:[
            TitleField,
            DescriptionField
        ],
        location:[
            LocationsField
        ]
    },
    [OFFERTYPE.BUY1_GET1]:{
        details_and_pricing:[          
            redemptionTypeField,
            redemptionLimitField,
            isMultiItemsField,
            PriceField,
            RangeDatePicker
        ],
        description:[
            TitleField,
            DescriptionField
        ],
        location:[
            LocationsField
        ]
    },
}